:root {
  --imgHeight: 45em;
  --headerTop: 35em;
}
.header-wrapper {
  background-color: #2A361A !important;
}
.bgImg {
  display: none;
}
.header-wrapper .item {
  color: #000 !important;
}
.header-wrapper .icon {
  fill: #fff !important;
}
.header-wrapper .transparent.input input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.header-wrapper .transparent.input input::placeholder,
.header-wrapper .transparent.input input {
  color: rgba(255, 255, 255, 0.8);
}
.header-wrapper .transparent.input input:focus::-moz-placeholder {
  color: #ffffff;
}
.header-wrapper .transparent.input input:focus::placeholder,
.header-wrapper .transparent.input input:focus {
  color: #ffffff;
}
.header-wrapper .searchbox.field {
  border-left: 1px solid #fff !important;
}
.header-wrapper .ui.secondary.pointing.menu .active.item {
  border-color: rgba(255, 255, 255, 0.7);
}
.header-wrapper .ui.secondary.pointing.menu .active.item:hover {
  border-color: #fff;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: #fff;
}
.hamburger.is-active .hamburger-inner:after {
  background-color: #000;
}
.tools-search-wrapper .tools {
  display: none !important;
}
@media only screen and (min-width: 990px) {
  .public-ui .header-wrapper {
    background-color: #2a361a !important;
    margin-top: 0 !important;
    width: 100%;
    z-index: 3;
    height: 10em;
    position: fixed !important;
    top: var(--headerTop);
    left: 0;
  }
  .public-ui .bgImg {
    display: block;
    height: var(--imgHeight);
    content: "";
    background-image: url(/static/media/header.5d6f06a1.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }
  .header-wrapper .item {
    color: #fff !important;
  }
  .cms-ui .header-wrapper {
    position: static !important;
    top: 0;
    margin-top: 0;
  }
  .cms-ui .bgImg {
    display: none;
  }
}
@media only screen and (min-width: 2230px) {
  :root {
    --imgHeight: 61em;
    --headerTop: 51em;
  }
  .bgImg {
    height: var(--imgHeight);
  }
  .ui.segment.header-wrapper {
    top: var(--headerTop);
  }
}
