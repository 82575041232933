#footer {
  border: none;
  background-color: #2A361A;
  margin-bottom: 0;
}
.footerList {
  display: flex;
  justify-content: center;
}
.footerList .item {
  color: #fff;
  padding: unset !important;
  margin: 0 1em;
}
i.ri-copyright-line {
  padding-right: 0.3em;
}
